import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { CallToAction } from '../components/Sections/CallToAction'
import { Card } from '../components/Sections/Card'
import Faq from '../components/Faq'
import { Seo } from '../components/Seo'

type PreguntasFrecuentesPageProps =
  PageProps<Queries.PreguntasFrecuentesPageQuery>

const PreguntasFrecuentesPage = ({
  data: {
    directus: { page, layout },
  },
}: PreguntasFrecuentesPageProps) => {
  if (!page) {
    return null
  }

  return (
    <Layout pageHeader={page.page_header}>
      <div className="container pt-10 pb-24">
        <Faq faq={page.faq} />
      </div>

      {layout?.about_card && (
        <Card className="py-10 bg-brand-light-gray" {...layout.about_card} />
      )}
      {layout?.contact_banner && (
        <CallToAction className="pt-16" {...layout.contact_banner} />
      )}
    </Layout>
  )
}

export default PreguntasFrecuentesPage

export { PageHead as Head } from '../components/PageHead'

export const query = graphql`
  query PreguntasFrecuentesPage {
    directus {
      page: page_preguntas_frecuentes {
        meta_description
        page_header {
          ...PageHeader
        }
        faq
      }
      layout {
        about_card {
          ...CardComponent
        }
        contact_banner {
          ...CallToActionComponent
        }
      }
    }
  }
`
