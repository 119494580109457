import * as React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import cn from 'classnames'

type FaqQuestion = {
  question: string
  answer: string
}

type FaqQuestions = {
  category: string
  questions: FaqQuestion[]
}

type FaqProps = {
  faq: FaqQuestions[]
}

export default function Faq({ faq }: FaqProps) {
  return (
    <div className="space-y-10">
      {faq.map(({ category, questions }) => (
        <div key={category}>
          <h3 className="font-bold text-brand-light-blue text-lg">
            {category}
          </h3>
          <dl className="mt-5 space-y-4">
            {questions.map(({ question, answer }) => (
              <Disclosure as="div" key={question}>
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button
                        className={cn(
                          'text-left w-full flex justify-between items-center bg-brand-light-gray px-4 py-2 hover:bg-opacity-70',
                          open ? 'text-brand-blue' : 'text-brand-dark-blue',
                        )}
                      >
                        <span className="font-semibold">{question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={cn(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform text-brand-light-blue',
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12 pb-2">
                      <div className="prose">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: answer,
                          }}
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      ))}
    </div>
  )
}
